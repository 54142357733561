import React from 'react';

const SocialMediaButtons = ({ page, contactInfo }) => {
    let wppButton, igButton;
    if(page === "home" || page === "sobre"){
        wppButton = '/resources/imgs/socialmedia/wpp_purple.png';
        igButton = '/resources/imgs/socialmedia/ig_purple.png';
    }
    if(page === "portfolio"){
        wppButton = '/resources/imgs/socialmedia/wpp_red.png';
        igButton = '/resources/imgs/socialmedia/ig_red.png';
    }
    if(page === "servicos"){
        wppButton = '/resources/imgs/socialmedia/wpp_green.png';
        igButton = '/resources/imgs/socialmedia/ig_green.png';
    }
    if(page === "contato"){
        wppButton = '/resources/imgs/socialmedia/wpp_orange.png';
        igButton = '/resources/imgs/socialmedia/ig_orange.png';
    }
    return (
        <div className={"flex flex-col gap-8 fixed bottom-0 right-0 h-80 m-4 p-2 font-mono text-white rounded-full items-center justify-center z-50 scale-50"}>
            <a href={contactInfo["ig"]} className={"hidden lg:block"}>
                <img src={igButton} alt=""/>
            </a>
            <a href={contactInfo["wpp"]} className={"hidden lg:block"}>
                <img src={wppButton} alt=""/>
            </a>
        </div>
    );
};

export default SocialMediaButtons;